let veluesCards = $('.values-card');
if(veluesCards.length){
    veluesCards.each((idx, card)=>{
        let hiddenContent = $(card).find('.values-card-text');
        $(card).hover(function(){
            $(card).addClass('active');
            hiddenContent.stop(true, true).slideDown();
        },function(){
            $(card).removeClass('active');
            hiddenContent.stop(true, true).slideUp(150);
        })
    })
}