import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let rolesSwiperWr = document.querySelectorAll('.roles-swiper-wr');
rolesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.slider-btn.next');
    let prevEl = el.querySelector('.slider-btn.prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;

    let loop = false;
    let loopedSlides = false;

    if (slidesCount >= 5) {
      loop = true;
      loopedSlides = slidesCount * 4;
    }

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 800,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      /* autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                },*/
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        600: {
          slidesPerView: 2,
          loop: slidesCount > 2,
        },
        1024: {
          slidesPerView: 3,
          loop: slidesCount > 3,
        },
        1100: {
          slidesPerView: 4,
          loop: slidesCount > 4,
        },
      },
    });
    swiperObserver(swiper);
  }
});
