import Swiper, { Autoplay, Grid, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const teamsSlider = document.getElementsByClassName('teams-section__slider')[0];

if (teamsSlider) {
  const teamsSlidesArr = teamsSlider.querySelectorAll('.swiper-slide');
  /*let loop = false;
  let loopedSlides = false;

  if (teamsSlidesArr.length >= 4) {
    loop = true;
    loopedSlides = teamsSlidesArr.length * 4;
  }*/

  const teamsSwiper = new Swiper('.teams-section__slider', {
    modules: [Autoplay, Navigation, Grid],
    slidesPerView: 2,
    spaceBetween: 16,
    grid: {
      rows: 2,
    },
    threshold: 10,
    /*loop: true,
    loopedSlides: 10,*/
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.teams-slider-buttons .slider-btn.prev',
      nextEl: '.teams-slider-buttons .slider-btn.next',
    },
    breakpoints: {
      421: {
        slidesPerView: 2,
        spaceBetween: 18,
        grid: {
          rows: 2,
        },
      },
      652: {
        slidesPerView: 3,
        spaceBetween: 16,
        grid: {
          rows: 2,
        },
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
        grid: {
          rows: 2,
        },
      },
      952: {
        slidesPerView: 3,
        spaceBetween: 20,
        grid: {
          rows: 2,
        },
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 20,
        grid: {
          rows: 2,
        },
      },
    },
  });

  swiperObserver(teamsSwiper);
}
