Splitting();

function parallax(e) {
  const elements = document.querySelectorAll('.decor-parallax');

  elements.forEach((elem) => {
    const speed = elem.getAttribute('data-speed');
    const rect = elem.getBoundingClientRect();

    if (rect.top < window.innerHeight && rect.bottom > 0) {
      const x = (window.innerWidth - e.pageX * speed) / 400;
      const y = (window.innerHeight - e.pageY * speed) / 400;

      elem.style.transform = `translateX(${x}px) translateY(${y}px)`;
      /*elem.style.transform = `translateY(${y}px)`;*/
    }
  });
}

if (
  window.innerWidth >= 1025 &&
  document.getElementsByClassName('decor-parallax')[0]
) {
  document.addEventListener('mousemove', parallax);
}

const canvasGradient = document.getElementsByClassName('canvas-gradient')[0];

if (canvasGradient && window.innerWidth >= 769) {
  const canvasGradientArr = document.querySelectorAll('.canvas-gradient');
  canvasGradientArr.forEach((elem, i) => {
    const parent = elem.parentNode;

    let gradients = [
      ['#80f9fa', '#80f9fa', '#00085f', '#00085f'],
      ['#80f9fa', '#232865', '#363e83', '#80f9fa'],
      ['#00085f', '#22255b', '#80f9fa', '#80f9fa'],
      ['#80f9fa', '#232c5b', '#3e428d', '#80f9fa'],
    ];

    if (elem.classList.contains('grey-gradient')) {
      gradients = [
        ['#00000f', '#4CB9F2'],
        ['#4CB9F2', '#00000f'],
      ];
    }

    const granimInstance = new Granim({
      element: elem,
      direction: 'diagonal',
      isPausedWhenNotInView: false,
      states: {
        'default-state': {
          gradients: gradients,
          transitionSpeed: 3000,
          loop: true,
        },
      },
    });
    // granimInstance.pause();
    //
    // parent.addEventListener("mouseenter", () => {
    // 	// granimInstance.changeState("default-state");
    // 	granimInstance.play();
    // });
    //
    // parent.addEventListener("mouseleave", () => {
    // 	// granimInstance.changeState("start-state");
    // 	granimInstance.pause();
    // });
  });
}
