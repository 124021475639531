import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const gallerySlider = document.getElementsByClassName(
  'gallery-section__slider'
)[0];

if (gallerySlider) {
  const gallerySlidesArr = gallerySlider.querySelectorAll('.swiper-slide');
  let loop = false;
  let loopedSlides = false;

  if (gallerySlidesArr.length >= 4) {
    loop = true;
    loopedSlides = gallerySlidesArr.length * 4;
  }

  const gallerySwiper = new Swiper('.gallery-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 10,
    threshold: 10,
    loop: loop,
    loopedSlides: loopedSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.gallery-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.gallery-slider-buttons.slider-buttons .slider-btn.next',
    },
    breakpoints: {
      640: {
        spaceBetween: 20,
        slidesPerView: 'auto',
      },
    },
  });

  swiperObserver(gallerySwiper);
}
