import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let offerSwiperWr = document.querySelectorAll('.offer-swiper-wr')
offerSwiperWr.forEach(el=>{
        if(el) {
            let swiperEl = el.querySelector('.swiper');
            let nextEl = el.querySelector('.slider-btn.next');
            let prevEl = el.querySelector('.slider-btn.prev');
            let slidesCount = el.querySelectorAll('.swiper-slide').length;
            let swiper = new Swiper(swiperEl, {
                modules: [Autoplay, Navigation],
                slidesPerView: 1,
                spaceBetween: 21,
                speed: 800,
                threshold: 10,
               /* autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                },*/
                navigation: {
                    nextEl: nextEl,
                    prevEl: prevEl,
                },
                breakpoints: {

                    500:{
                        slidesPerView: 2,
                    },
                    1024:{
                        slidesPerView: 3,
                    }

                },
                on:{
                    afterInit: function(swiper){
                        /*Show/Hide text content*/
                        let slides = swiper.$el[0].querySelectorAll('.swiper-slide');
                        slides.forEach(slide =>{
                          slide.addEventListener('mouseenter', function(){
                              $(this).find('.offer-card').addClass('hover');
                              $(this).find('.offer-card__text').stop(true, true).slideDown();
                          });
                            slide.addEventListener('mouseleave', function(){
                                $(this).find('.offer-card').removeClass('hover');
                                $(this).find('.offer-card__text').stop(true, true).slideUp();
                            })
                        })
                    }
                }
            })
            swiperObserver(swiper);
        }
    })