import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const testimonialsSlider = document.getElementsByClassName(
  'testimonials-slider'
)[0];

if (testimonialsSlider) {
  const testimonialsSlidersArr = document.querySelectorAll(
    '.testimonials-slider'
  );

  for (let i = 0; i < testimonialsSlidersArr.length; i++) {
    const testimonialsSwiper = new Swiper(testimonialsSlidersArr[i], {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 10,
      threshold: 10,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: testimonialsSlidersArr[i].querySelector(
          '.testimonials-slider-buttons.slider-buttons .slider-btn.prev'
        ),
        nextEl: testimonialsSlidersArr[i].querySelector(
          '.testimonials-slider-buttons.slider-buttons .slider-btn.next'
        ),
      },
    });

    swiperObserver(testimonialsSwiper);
  }
}
