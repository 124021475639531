import { followingSidebar } from '../utils/sidebar.utils.js';

const sidebar = document.querySelector('#sidebar');
const contentSticky = document.querySelector('#content-sticky');
const filtersBtn = document.querySelector('.filters-btn');
const contentResult = document.querySelector('.job-search-section__result');

if (sidebar && window.innerWidth >= 640) {
  followingSidebar({ sidebar, contentSticky });
}

if (filtersBtn) {
  filtersBtn.addEventListener('click', () => {
    filtersBtn.classList.toggle('active');
    contentSticky.classList.toggle('active');
    contentResult.classList.toggle('active');
  });

  contentResult.addEventListener('click', () => {
    if (contentResult.classList.contains('active')) {
      filtersBtn.classList.remove('active');
      contentSticky.classList.remove('active');
      contentResult.classList.remove('active');
    }
  });
}
