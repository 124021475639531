const progressionSection = document.getElementsByClassName(
  'progression-section'
)[0];
if (progressionSection) {
  const itemCirclesArr = progressionSection.querySelectorAll(
    '.progression-section__item'
  );
  const itemContentsArr = progressionSection.querySelectorAll(
    '.progression-section__item-content'
  );

  itemCirclesArr.forEach((circle, i) => {
    const button = circle;
    const tooltip = itemContentsArr[i];

    function showTooltip() {
      const itemContentsArr = progressionSection.querySelectorAll(
        '.progression-section__item-content'
      );
      for (let j = 0; j < itemContentsArr.length; j++) {
        itemContentsArr[j].classList.remove('active');
        itemContentsArr[j].parentElement
          .querySelector('.progression-section__item-circle')
          .classList.remove('active');
        itemContentsArr[j].parentElement.classList.remove('active');

        setTimeout(() => {
          const animations = itemContentsArr[j].querySelectorAll('.animated');
          if (animations.length) {
            for (let k = 0; k < animations.length; k++) {
              if (i !== j) {
                animations[k].classList.remove('animated');
              }
            }
          }
        }, 300);
      }

      tooltip.classList.add('active');
      button.classList.add('active');
      button
        .querySelector('.progression-section__item-circle')
        .classList.add('active');
      setTimeout(() => {
        tooltip.style.opacity = '1';
      }, 10);
    }

    [
      ['mouseenter', showTooltip],
      ['click', showTooltip],
    ].forEach(([event, listener]) => {
      button.addEventListener(event, listener);
    });

    if (i === 0) {
      showTooltip();
    }
  });
}
