// Hovering buttons
const followOnHover = (hovered, following) => {
  const onMouseMove = (event) => {
    let x = event.offsetX;
    let y = event.offsetY;

    if (event.target.classList.contains('btn-link')) {
      following.style.transform = `translate(calc(${x}px - 50%), calc(${y}px - 150%)) scale(2)`;
      following.style.borderWidth = '1px';
      following.style.backgroundColor = 'transparent';
      following.style.borderColor = 'var(--cl-purple)';
    }
  };

  const removeMouseMove = () => {
    following.style.transform = `translate(0, -50%) scale(1)`;
    following.style.borderWidth = '';
    following.style.backgroundColor = 'var(--cl-yellow)';
    following.style.borderColor = 'var(--cl-yellow)';
  };

  hovered.addEventListener('mousemove', onMouseMove);

  hovered.addEventListener('mouseleave', removeMouseMove);

  if (window.innerWidth < 1025) {
    hovered.removeEventListener('mousemove', onMouseMove);
    hovered.removeEventListener('mouseleave', removeMouseMove);
  }
};

window.hoverButton = followOnHover;

const buttons = document.querySelectorAll('.btn-link');
if (buttons.length) {
  buttons.forEach((btn) => {
    let circle = btn.querySelector('.circle');

    followOnHover(btn, circle);
  });
}
