import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const marketsSlider = document.querySelector('.markets-section__slider');

let marketsSwiper = null;

function initSlider() {
  marketsSwiper = new Swiper('.markets-section__slider', {
    modules: [Autoplay, Navigation],
    slidesPerView: 1,
    spaceBetween: 10,
    threshold: 10,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.markets-slider-buttons.slider-buttons .slider-btn.prev',
      nextEl: '.markets-slider-buttons.slider-buttons .slider-btn.next',
    },
  });

  /*swiperObserver(marketsSwiper);*/
}

function destroySlider() {
  if (marketsSwiper !== null) {
    marketsSwiper.destroy();
    marketsSwiper = null;
  }
}

function handleResize() {
  if (window.innerWidth <= 640) {
    if (marketsSwiper === null) {
      initSlider();
    }
  } else {
    destroySlider();
  }
}

if (window.innerWidth <= 640) {
  initSlider();
}

window.addEventListener('resize', handleResize);

window.addEventListener('load', handleResize);
