import './utils/vh.utils.js';
import './utils/safari.utils.js';

import './utils/animate-on-view.utils.js';

import './components/animations.component.js';
import './components/header.component.js';
import './components/buttons.component.js';
import './components/jobs.component.js';
import './components/jobs-search.component.js';
import './components/progress.component.js';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/player.component.js';
/*import './components/specialized.component.js';*/

/*Sliders*/
import './components/gallery.component.js';
import './components/markets.component.js';
import './components/testimonials.component.js';
import './components/offer.component.js';
import './components/role.component.js';
import './components/recent-insights.component.js';
import './components/related-jobs.component.js';
import './components/teams.component.js';

/*import './components/our-values.component.js';*/
/*End sliders*/

import './components/values.component.js';

import './libs/countUp.lib.js';

/*var popupFD = document.querySelector('#popup');
if (popupFD) {
  /!*var popupTitle = popupFD.querySelector(".popup__title");*!/
  var popupBlock = popupFD.querySelector('#popup-block');

  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    popupFD.classList.add('active');
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    var scrollContentElement = document.querySelector('.popup__content');

    if (scrollContentElement) {
      var simpleBarInstance = new SimpleBar(scrollContentElement);
      simpleBarInstance.recalculate();
    }
  };
}*/

/*window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};*/
